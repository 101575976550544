export function criteriaList2(){
    const criteria =
        [

            {
                id: 1,
                title: 'Сопоставление промпту генерируемого вокала (тембр, окрас голоса, может быть пол)',
                sections:
                    [
                        {
                            id: 1,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 2,
                title: 'Музыкальное качество генерируемого вокала (наличие плавных связок между словами, вокал поется в одной тональности и в одном темпе)',
                sections:
                    [
                        {
                            id: 2,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 3,
                title: 'Отсутствие пропуска слов при генерации вокала по тексту',
                sections:
                    [
                        {
                            id: 3,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 4,
                title: 'Уровень реализации (концепция/прототип и т.д.)',
                sections:
                    [
                        {
                            id: 4,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 5,
                title: 'Релевантность поставленной задачи (команда погрузилась в отрасль, проблематику; предложенное решение соответствует поставленной задаче',
                sections:
                    [
                        {
                            id: 5,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },

        ];
    return criteria;


}