<script setup>
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
const store = useStore()
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])

onMounted( ()=>{
  try {
    init()
  } catch (err) {
    alert ('APPLICATION ERROR')
  }
})

const init = async () => {
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)
  store.commit('menuModule/initMenu')
}

//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const userId = computed( () => {return store.getters['authModule/userId']})

</script>
<template>
  <main class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col"><h3>О мероприятии</h3></div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="card card-outline card-info">
              <div class="card-header pt-3">
                <h4>XLabs AI Hack 2024</h4>
              </div>
              <div class="card-body">

                С 1 по 17 ноября состоится XLabs AI Hack 2024 — уникальный ML хакатон, где у участников есть возможность проявить креатив, повлиять на решения в области искусственного интеллекта и создать систему для генерации вокала.

                <br><br>
                <p><b>Формат хакатона:</b> гибридный.</p>
                <p><b>Место проведения:</b> онлайн (2.11 — 10.11), оффлайн (17.11).</p>
                <p><b>Участники соревнования:</b> участниками соревнований могут стать лица в возрасте от 18 лет из РФ и Беларуси (DL engineer, Data engineer, Back-разработчики, Product Менеджеры).</p>
                <p><b>Задача:</b> В рамках хакатона необходимо разработать нейросеть, которая сможет генерировать вокал по текстовому запросу и заданным словам.</p>
                <p>Общий призовой фонд соревнований — 2 000 000 рублей, с наградами для первых трех победителей:</p>

                <ul>
                  <li>1 место — 1 000 000 рублей;</li>
                  <li>2 место — 600 000 рублей;</li>
                  <li>3 место — 400 000 рублей.</li>
                </ul>

                <hr>

              </div>
            </div>


            <div class="card card-outline card-danger">
              <div class="card-header pt-3">
                <h4>Организатор</h4>
              </div>
              <div class="card-body">
                <div class="row">

                    <div class="col mb-4 text-center">
                      <img src="https://lk.xlabsaihack.com/images/logo/xlabs.png" class="img-fluid">
                      <p class="mt-3">XLabs AI — это часть международной компании, которая занимается вопросами в сфере искусственного интеллекта и нейронных сетей.</p>
                    </div>

                </div>
              </div>
            </div>

            <div class="card card-outline card-danger d-none">
              <div class="card-header pt-3">
                <h4>Партнеры</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/sber.png" class="img-fluid">
                    <p class="mt-3">Сбер</p>
                  </div>
                  <div class="col-md-3 col-sm-12 mb-4 text-center">
                    <img src="https://%D0%BB%D0%BA.%D1%81%D0%B2%D0%BE%D0%B9%D0%BA%D0%BE%D0%B4.%D1%80%D1%84/images/logo/1c.png" class="img-fluid">
                    <p class="mt-3">1С</p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

  </main>
</template>

<style scoped>
.logo{
  max-width: 350px;
  max-height: 200px;
  -webkit-filter: saturate(0%);
  filter: saturate(0%);
}
</style>