<script setup>
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {ref, computed, onMounted} from "vue";
import axios from "axios";

// import {criteriaList} from "@/use/criteria-list";
// критерии оценки
import TheCriteria from "@/components/Admin/TheCriteria";
// результаты для админстратора
import TheCriteriaAll from "@/components/Admin/TheCriteriaAll";
// запрос на role делаем каждый раз, не только при авторизации
const userData = ref([])
//----------------------------------------------------------------------

//const text = ref('slkdfj jkfjldfjs https://yandex.ru/maps/2/saint-petersburg/transport/ sdfsdf sf sdf sdfsfsdfsd')
const store = useStore()
const route = useRoute()
//const router = useRouter()
const isLoading = ref(false)
const team = ref([])
const isTeamExist = ref(false)
const members = ref([])
const membersCount = ref(0)
const solutions = ref([])
const solutionsCount = ref(0)
// компонент критерии доступен для жюри
// const isCriteriaAvailable = ref(true)

//----------------------------------------------------------------------

onMounted( ()=>{
  init()
})

const init = async () => {
  isLoading.value = true
  store.commit('menuModule/initMenu')

  const url = process.env.VUE_APP_URL + "/api/team"
  const urlM = process.env.VUE_APP_URL + "/api/members"
  const urlS = process.env.VUE_APP_URL + "/api/solutions"
  // показывать или скрыть критерии
  // const urlC = process.env.VUE_APP_ADMIN_URL + "/api/data/config.php"
  const urlData =  process.env.VUE_APP_ADMIN_URL + "/api/userData.php"

  // userId.value получен при авторизации
  await axios
      .get(`${urlData}?token=${token.value}&id=${userId.value}`)
      .then(response => (userData.value = response.data))

  await axios
      .get(`${url}/${token.value}/${route.params.id}`)
      .then(response => (team.value = response.data))

  if (team.value.length === 0) {
      // коменды нет

    setTimeout(() => isLoading.value = false, 3000);

  } else {

    isTeamExist.value = true

    await axios
        .get(`${urlM}/${token.value}/${route.params.id}`)
        .then(response => (members.value = response.data))

        .then(
            await axios
                .get(`${urlS}/${token.value}/${route.params.id}`)
                .then(response => (solutions.value = response.data))
        )
        .catch(error => alert(error));

    membersCount.value = members.value.data.length
    solutionsCount.value = solutions.value.data.length
    isLoading.value = false
  }

  // обновить данные о пользователе
  store.commit('userModule/setUserData', userData.value)

}

//----------------------------------------------------------------------

const activeComponent = computed( () => {
  if (user.value) {
    if (+user.value['role'] === 100) {
      // результаты (админ)
      return TheCriteriaAll
    } else {
      return TheCriteria
    }
  } else {
    return null
  }
})

const createLink = (text) => {
  let a = text.replace( /<br/g, "" )
  let b = a.replace( /\/>/g, "" )
  let c = b.replace( /\r/g, "" )
  let d = c.replace( /\n/g, "" )
  const arr = d.split(' ');
  let result = arr.map(function(item) {
    if (item.lastIndexOf("http") >= 0) {
      return '<a href="' + item + '" target="_blank">' + item + '</a>';
    } else {
      if (item == ''){
        return '<br />';
      } else {
        return item;
      }
    }
  });
  const newText = result.join(' ');
  return newText
}


//----------------------------------------------------------------------
const token = computed( () => {return store.getters['authModule/token']})
const user = computed( () => {return store.getters['authModule/user']})
const userId = computed( () => {return store.getters['authModule/userId']})
</script>

<template>
  <main v-if="isTeamExist" class="content-wrapper">

    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h3>Команда -
            <span v-if="team.data">
              <span v-for="(team,index) in team.data" :key="index">
                  {{ team['original']['team'] }}
              </span>
            </span>
            </h3>
            <div v-if="team.data">
              <div v-for="(team,index) in team.data" :key="index">
                {{ team['original']['description'] }}
                <div class="mt-2"><a :href="'https://github.com/hackathonsrus/'+ team['original']['repo']" target="_blank">Репозиторий команды</a></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">

        <!-- Задача -->

        <div class="row">
          <div class="col-12 mt-3">
            <h3>
              <v-icon
                  class="default-icon mr-2"
                  icon="mdi:comment-question-outline"
              ></v-icon>
              Задача</h3>

          </div>
          <div class="col-12 mt-2" v-if="team.data">
            <div v-for="(team,index) in team.data" :key="index" class="card card-outline card-info">
              <div class="card-body">
                <div v-if="team['original']['Task']" v-html="team['original']['Task']"></div>
                <div v-else>не выбрана</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Участники команды -->

        <div class="row" v-if="membersCount > 0">
          <div class="col-12 mt-3">
            <h3>
              <v-icon
                  class="default-icon mr-2"
                  icon="mdi:account-multiple"
              ></v-icon>
              Участники команды</h3>
          </div>
          <div
              v-for="(member,index) in members.data" :key="index"
              class="col-md-3 col-sm-12 mt-2"
          >
            <div class="card card-outline card-info">
              <div class="card-body text-center">

                <img
                    v-if="member['original']['avatar']"
                    class="profile-user img-circle"
                    style="object-fit: cover;"
                    :src="'https://lk.xlabsaihack.com/storage/images/avatars/' + member['original']['avatar']"
                >
                <img
                    v-else
                    class="profile-user img-circle"
                    src="https://jury.xlabsaihack.com/images/profile.png"
                >
              </div>
              <div class="card-footer text-center">{{ member['original']['name'] }}<br>{{ member['original']['surname'] }}</div>
            </div>
          </div>
        </div>

        <hr>

        <!-- Решения команды -->

        <div class="row">
          <div class="col-12 mt-3" v-if="solutionsCount > 0">
            <h3 class="text-center">Решения команды</h3>
            <div class="number mt-4">1</div>
            <div class="v-line"></div>

            <div
                v-for="(solution,index) in solutions.data" :key="index"
            >
              <div
                  class="card card-outline card-info m-0"
              >
                <div class="card-header">Отправлено {{ solution['original']['created_at'] }}</div>
                <div class="card-body" v-html="createLink(solution['original']['solution'])"></div>
              </div>

              <div v-if="index < (solutionsCount-1)">
                <div class="v-line"></div>
                <div class="number">{{ index+2 }}</div>
                <div class="v-line"></div>
              </div>
            </div>
          </div>

          <!-- Администратор подключает компонент с результатами, член жюри - критерии оценки  -->
          <div v-if="user" class="col-12 mt-5 mb-4">
              <component
                  :is="activeComponent"
                  :teamId = "route.params.id"
                  :userId = "user['id']"
              ></component>
          </div>

        </div>

        <div class="d-flex justify-content-center">
          <div
              v-if="isLoading"
              class="spinner-border text-primary m-4" role="status"
          ></div>
        </div>

      </div>
    </section>
    <br><br><br>
  </main>
  <main v-else class="content-wrapper">
    <section class="content">
        <div class="text-center">
          <div class="spinner-border text-primary m-4" role="status"></div>
        </div>
    </section>
  </main>
</template>
<style scoped>
.profile-user{
  width:120px;
  height:120px;
}
</style>