export function criteriaList(){
    const criteria =
        [
            {
                id: 1,
                title: 'Запускаемость кода',
                sections:
                    [
                        {
                            id: 1,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]
            },
            {
                id: 2,
                title: 'Адаптивность/Масштабируемость',
                sections:
                    [
                        {
                            id: 2,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]

            },
            {
                id: 3,
                title: 'Отсутствие в использовании закрытых API',
                sections:
                    [
                        {
                            id: 3,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },
                    ]

            },
            {
                id: 4,
                title: 'Использование указанного стека технологий',
                sections:
                    [
                        {
                            id: 4,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 5,
                title: 'Аккуратно оформленный репозиторий GitHub',
                sections:
                    [
                        {
                            id: 5,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 6,
                title: 'Оформленный по стандартам языка Python код',
                sections:
                    [
                        {
                            id: 6,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 7,
                title: 'Предоставление источников данных',
                sections:
                    [
                        {
                            id: 7,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },


            {
                id: 8,
                title: 'Сопоставление промпту генерируемого вокала (тембр, окрас голоса, может быть пол)',
                sections:
                    [
                        {
                            id: 8,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 9,
                title: 'Музыкальное качество генерируемого вокала (наличие плавных связок между словами, вокал поется в одной тональности и в одном темпе)',
                sections:
                    [
                        {
                            id: 9,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 10,
                title: 'Отсутствие пропуска слов при генерации вокала по тексту',
                sections:
                    [
                        {
                            id: 10,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 11,
                title: 'Уровень реализации (концепция/прототип и т.д.)',
                sections:
                    [
                        {
                            id: 11,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },
            {
                id: 12,
                title: 'Релевантность поставленной задачи (команда погрузилась в отрасль, проблематику; предложенное решение соответствует поставленной задаче',
                sections:
                    [
                        {
                            id: 12,
                            name: '',
                            values: 10,
                            activeIndex: 0,
                        },

                    ]

            },

        ];
    return criteria;


}